import React from "react";
import "./index.css";

export const NeedPermisions = ({ category }) => {
  if (category === "drive") {
    return (
      <div style={{ width: "80%" }}>
        <h2 style={{ fontWeight: 600 }}> ⚠️ We need permissions to proceed</h2>
        <h5>
          It seems like you didn't give RED full access to google drive
          permissions, and we need access to proceed with the financials module.
        </h5>
        <br />
        <h5>Please do the following steps:</h5>
        <br />
        <h6>
          1) Log out from your session (in the button located in the lower left
          corner)
        </h6>
        <h6>2) Sign in with the same email</h6>
        <h6>
          3) When google asks you for permissions, check on the "Google Drive"
          checkbox
        </h6>
      </div>
    );
  } else if (category === "calendar-permissions") {
    return (
      <div className="calendarpermissions__container">
        <div className="calendarpermissions__title">No Events</div>
        <div className="calendarpermissions__subtitle"></div>
      </div>
    );
  } else {
    return (
      <div style={{ width: "80%" }}>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ fontWeight: 600, color: "red" }}> X</h2>
          <h5 style={{ fontWeight: 600 }}>
            You don't have access to the calendar yet
          </h5>
          <br />
        </div>
        <br />
        <h5 style={{ fontWeight: 500 }}>Please do the following steps:</h5>
        <br />
        Communicate with us at ignacio@stoprecidivism.org for we to help you
      </div>
    );
  }
};
