import React from "react";
import { useDispatch } from "react-redux";
import Button from "src/components/shared/Button";
import "./index.css";
import { toggleSignupModal } from "../../../../store/authUser/authUserActions";
import image from "src/components/Landing/components/NewActionContainer/red-action-image.png";

export const NewActionContainer = ({
  isLoggedIn = false,
  authUser,
  joinAs,
  handleJoinAs,
  history,
  classId,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="newaction__container">
        <div className="titleandimage__container">
          <div className="newactionimage__container">
            <div>
              <img src={image} alt="" className="newactionimage__image" />
            </div>
          </div>
          <div className="newactioninfo__container">
            <div className="newaction__title1">Welcome to</div>
            <div className="newaction__title2">
              RED Ed <span className="tm__span">™️</span>
            </div>
            {/* {isLoggedIn ? "Hello Nicolas!" : "Login"} */}
            <div className="actiondescription__container">
              It’s the first program to get people out of the criminal justice
              system with a 90% recidivism rate reduction.
            </div>
            {classId ? (
              <div className="classId__container">{classId}</div>
            ) : null}
            <div className="newactionbuttons__container">
              {!authUser ? (
                <>
                  <div
                    className="landing__button"
                    onClick={() => {
                      handleJoinAs("student");
                      dispatch(toggleSignupModal(true));
                    }}
                  >
                    Join as Student
                  </div>
                  <div
                    className="landing__button"
                    onClick={() => {
                      handleJoinAs("mentor");
                      dispatch(toggleSignupModal(true));
                    }}
                  >
                    Join as Mentor
                  </div>
                  {/* <Button
                    title={`Join as Student`}
                    onClick={() => {
                      handleJoinAs("student");
                      dispatch(toggleSignupModal(true));
                    }}
                  />
                  <Button
                    title="Join as Mentor"
                    type="outline"
                    onClick={() => {
                      handleJoinAs("mentor");
                      dispatch(toggleSignupModal(true));
                    }}
                  /> */}
                </>
              ) : (
                <Button
                  title="Go to Dashboard"
                  onClick={() => {
                    history.push("/home");
                  }}
                />
              )}
            </div>
            {/* <div
              className="newactionstats__container"
              style={{ marginTop: 60 }}
            >
              <div className="newactionstatsbox">
                <span
                  className="statnumberred"
                  style={{ fontFamily: "Montserrat" }}
                >{`< `}</span>
                <span className="statnumberred">10%</span>
                <div className="statinfo">RED Recidivism Rate</div>
              </div>
              <div className="newactionstatsbox">
                <div className="statnumber">65%</div>
                <div className="statinfo">
                  Traditional Adjudication Recidivism Rate
                </div>
              </div>
            </div> */}
            {/* <div className="newactionstats__container">
              <div className="newactionstatsbox">
                <div className="statnumberred">
                  <div className="statnumber">$7,500</div>
                  <div className="statnumberllight">Per Year</div>
                </div>
                <div className="statinfo">Cost of RED Participant</div>
              </div>
              <div className="newactionstatsbox">
                <div className="statnumber">$20,000+</div>
                <div className="statnumberllight">Per Year</div>
                <div className="statinfo">Post Adjudication Cost</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
